<template>
    <div class="misc-wrapper">
        <b-link class="brand-logo">
            <b-img
                :src="appLogoImage"
                alt="logo"
                width="220"
            />
        </b-link>

        <div class="misc-inner p-2 p-sm-3">
            <div class="w-100 text-center">
                <h2 class="mb-1">Página no encontrada 🕵🏻‍♀</h2>
                <p class="mb-2">
                    ¡Lo sentimos! 😖 La URL solicitada no se encontró en esta plataforma.
                </p>
                <b-button variant="primary" class="mb-2 btn-sm-block" :to="{path:'/'}">
                    Regresar al inicio
                </b-button>
                <b-img fluid :src="imgUrl" alt="Error page" />
            </div>
        </div>
    </div>
</template>

<script>

    import { $themeConfig } from '@themeConfig';
    import store from '@/store/index';

    export default {
        data() {
            return {
                downImg: require('@/assets/images/pages/error.svg')
            }
        },
        computed: {
            imgUrl() {
                if (store.state.appConfig.layout.skin === 'dark') 
                {
                    this.downImg = require('@/assets/images/pages/error-dark.svg');
                }

                return this.downImg;
            }
        },
        setup() {
			const { appLogoImage } = $themeConfig.app;
            
			return {
				appLogoImage
			}
		}
    }

</script>

<style lang="scss">
    @import '@core/scss/vue/pages/page-misc.scss';
</style>